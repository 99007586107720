@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add this to your CSS file */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader {
    border: 8px solid rgba(255, 255, 255, 0.8); /* Mostly white with transparency */
    border-top: 8px solid orange; /* Top part is orange */
    border-radius: 50%;
    animation: spin 2s linear infinite; /* Use the spin animation */
}
